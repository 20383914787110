import { Link } from "react-router-dom";
import logo from '../images/logo.png';
import logoBig from '../images/logo-big.png';
import menuIcon from '../images/icon-menu.png';
import closeIcon from '../images/icon-close-gold.png';
import { useState } from "react";

export interface INavbarProps {
    currentPage: string;
    // onShowChange: (value: boolean) => void;
}

export const Navbar = (props: INavbarProps) => {
    const { currentPage } = props;

    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

    return <nav className={`nav ${showMobileMenu && 'nav--open'}`}>
        <div className={`nav__wrapper`}>
            <Link to="/">
                <img src={logo} title="Strona główna" className="nav__logo" alt="AH Group logo" />
                <img src={logoBig} title="Strona główna" className="nav__logo nav__logo--big" alt="AH Group logo" />
            </Link>

            <div className="nav__menu-wrapper">
                <Link to="/ah-group" className={`nav__menu-item ${currentPage === 'ah-group' && 'nav__menu-item--active'}`}>AH Group</Link>
                <Link to="/historia" className={`nav__menu-item ${currentPage === 'historia' && 'nav__menu-item--active'}`}>Historia</Link>
                <Link to="/wartosci" className={`nav__menu-item ${currentPage === 'wartosci' && 'nav__menu-item--active'}`}>Wartości</Link>
                <Link to="/artur-hawryluk" className={`nav__menu-item ${currentPage === 'artur-hawryluk' && 'nav__menu-item--active'}`}>Artur Hawryluk</Link>
                <Link to="/biura-spolek" className={`nav__menu-item ${currentPage === 'biura-spolek' && 'nav__menu-item--active'}`}>Biura spółek</Link>
                <Link to="/aktualnosci" className={`nav__menu-item ${currentPage === 'aktualnosci' && 'nav__menu-item--active'}`}>Aktualności</Link>
                <Link to="/kontakt" className={`nav__menu-item ${currentPage === 'kontakt' && 'nav__menu-item--active'}`}>Kontakt</Link>
            </div>

            <img onClick={() => setShowMobileMenu(!showMobileMenu)} className="nav__menu-icon" src={menuIcon} alt="Menu" />

            <div className={`nav__menu-wrapper-mobile ${showMobileMenu && 'nav__menu-wrapper-mobile--show'}`}>
                <div className={`nav--mobile ${showMobileMenu && 'nav--open'}`}>
                    <img src={logo} title="Strona główna" className="nav__logo" alt="AH Group logo" />
                    <img src={logoBig} title="Strona główna" className="nav__logo nav__logo--big" alt="AH Group logo" />

                    <img onClick={() => setShowMobileMenu(!showMobileMenu)} className="nav__close-icon" src={closeIcon} alt="Zamknij" />
                </div>

                <Link to="/ah-group" className={`nav__menu-item ${currentPage === 'ah-group' && 'nav__menu-item--active'}`}>AH Group</Link>
                <Link to="/historia" className={`nav__menu-item ${currentPage === 'historia' && 'nav__menu-item--active'}`}>Historia</Link>
                <Link to="/wartosci" className={`nav__menu-item ${currentPage === 'wartosci' && 'nav__menu-item--active'}`}>Wartości</Link>
                <Link to="/artur-hawryluk" className={`nav__menu-item ${currentPage === 'artur-hawryluk' && 'nav__menu-item--active'}`}>Artur Hawryluk</Link>
                <Link to="/biura-spolek" className={`nav__menu-item ${currentPage === 'biura-spolek' && 'nav__menu-item--active'}`}>Biura spółek</Link>
                <Link to="/aktualnosci" className={`nav__menu-item ${currentPage === 'aktualnosci' && 'nav__menu-item--active'}`}>Aktualności</Link>
                <Link to="/kontakt" className={`nav__menu-item ${currentPage === 'kontakt' && 'nav__menu-item--active'}`}>Kontakt</Link>
            </div>
        </div>
    </nav>;
}