import { Navbar } from '../../components/navbar';

import bannerImage from '../../images/artur-hawryluk/banner.png';
import section1Mobile from '../../images/artur-hawryluk/section1-mobile.png';
import section2Mobile from '../../images/artur-hawryluk/section2-mobile.png';
import section1 from '../../images/artur-hawryluk/section1.png';
import section2 from '../../images/artur-hawryluk/section2.png';

import { Footer } from '../../components/footer';
import { Banner } from '../../components/banner';
import { useEffect } from 'react';
import AppConfig from '../../utils/appconfig';

const currentPage: string = 'artur-hawryluk';

export function ArturHawryluk() {
  useEffect(() => {
    document.title = `${AppConfig.baseTabTitle} - Artur Hawryluk`;
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <Banner title={'Twórca i prezes grupy'} titlePrimary={'Artur Hawryluk'} backgroundImage={bannerImage}
        paragraph1Text="Przedsiębiorca oraz inwestor działający w wielu branżach rynku. Dawniej Dyrektor Generalny, Prezes Zarządu Everest Finanse S.A., obecnie akcjonariusz i współwłaściciel, a także założyciel i Prezes AH Group." />
      
      <div className="artur-hawryluk__section-wrapper">
        <img src={section1Mobile} className="artur-hawryluk__image--mobile" alt="Artur Hawryluk podczas pracy w biurze" loading="lazy" />
        <img src={section1} className="artur-hawryluk__image" alt="Artur Hawryluk podczas pracy w biurze" loading="lazy" />
        <p className="artur-hawryluk__text">
          Zdobyte na rynku finansowym doświadczenie wykorzystuje jako inwestor w branży developerskiej, chemicznej oraz wydobywczej i energetycznej. Kreuje 
          i rozwija strategie sprzedaży i marketingu, systemy zarządzania oraz strategie rozwoju dla kolejnych start-upów. 
          <br /><br />
          Sukcesywnie eksploruje rynki zagraniczne 
          w poszukiwaniu kolejnych inwestycji. Absolwent Stosunków Międzynarodowych w poznańskim Collegium Da Vinci. Członek Business Center Club, współzałożyciel Fundacji Rozwoju Rynku Finansowego.
        </p>
      </div>

      <div className="artur-hawryluk__section-wrapper artur-hawryluk__section-wrapper--reverse">
        <img src={section2Mobile} className="artur-hawryluk__image--mobile" alt="Uśmiechnięty Artur Hawryluk obok biurowca" loading="lazy" />
        <img src={section2} className="artur-hawryluk__image" alt="Uśmiechnięty Artur Hawryluk obok biurowca" loading="lazy" />
        <p className="artur-hawryluk__text">
          <span className="artur-hawryluk__text--bold">
            Artur Hawryluk to również człowiek wielu pasji, w które podobnie jak w biznes potrafi zaangażować się bez pamięci. 
          </span>
          <br /><br />
          Jest pasjonatem rajdów samochodowych 
          i licencjonowanym kierowcą wyścigowym. Regularnie dba o aktywność fizyczną ćwicząc pod okiem osobistego trenera. Duży nacisk kładzie na zdobywanie wiedzy 
          i rozwój własnych kompetencji związanych z relacjami z ludźmi i poznawaniem siebie. 
          <br /><br />
          Biegle posługuje się językiem angielskim, nieustannie dbając o jego jakość w pracy z lektorem i tłumaczem.
        </p>
      </div>

      <Footer />
    </div>
  );
}
