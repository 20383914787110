import localisations from '../../../images/ah-group/localisations.png';
import localisationsMobile from '../../../images/ah-group/localisations-mobile.png';
import iconChevron from '../../../images/icon-chevron.png';
import { SectionTitle } from '../../../components/sectionTitle';
import { useState } from 'react';

const LocalisationsSectionDetails = ({ title, text }: { title: string; text: string }) => {
    const [showText, setShowText] = useState<boolean>(false);

    return <div className="ah-group-localisations-details__wrapper" onClick={() => setShowText(!showText)} 
        title={showText ? 'Ukryj szczegóły' : 'Pokaż szczegóły'}
    >
        <div className="ah-group-localisations-details__title-wrapper">
            <p className="ah-group-localisations-details__title">{title}</p>

            <img src={iconChevron} alt="Ikona strzałki" 
                className={`ah-group-localisations-details__chevron-icon ${showText ? 'ah-group-localisations-details__chevron-icon--rotate' : ''}`}
            />
        </div>

        <p className={`ah-group-localisations-details__text ${showText ? 'ah-group-localisations-details__text--show' : ''}`}>
            {text}
        </p>
    </div>;
};

export const LocalisationsSection = () => {
    return <div className={`ah-group-localisations-section`}>
        <div className="content-wrapper">
            <SectionTitle title={"Zakres działalności"} titlePrimary={"AH Group"} />

            <img src={localisations} className="ah-group-localisations-section__image"
                alt={"Mapa świata z zaznaczonymi punktami działalności AH Group"} loading="lazy" />
            <img src={localisationsMobile} className="ah-group-localisations-section__image--mobile"
                alt={"Mapa świata z zaznaczonymi punktami działalności AH Group"} loading="lazy" />
            
            <div className="ah-group-localisations-section__content-wrapper--mobile">
                <LocalisationsSectionDetails title={'Śrem'} text={'Chopina 1J  / Centrala Spółki AH Group\n\rGrunwaldzka 27 C  / Invest Park H&H oraz Development H&H'} />
                <LocalisationsSectionDetails title={'Poznań'} text={'Chopina 1J  / Centrala Spółki AH Group\n\rGrunwaldzka 27 C  / Invest Park H&H oraz Development H&H'} />
                <LocalisationsSectionDetails title={'Wysogotowo'} text={'Chopina 1J  / Centrala Spółki AH Group\n\rGrunwaldzka 27 C  / Invest Park H&H oraz Development H&H'} />
                <LocalisationsSectionDetails title={'Zbrudzewo'} text={'Śremska 134 A / Jurga'} />
                <LocalisationsSectionDetails title={'Albania'} text={'Chopina 1J  / Centrala Spółki AH Group\n\rGrunwaldzka 27 C  / Invest Park H&H oraz Development H&H'} />
                <LocalisationsSectionDetails title={'Dubai'} text={'Fractal Recycling'} />
                <LocalisationsSectionDetails title={'Kosovo'} text={'Vardari'} />
                <LocalisationsSectionDetails title={'India'} text={'Nobilis India'} />
                <LocalisationsSectionDetails title={'Germany'} text={'Recycling Germany'} />
                <LocalisationsSectionDetails title={'Montenegro'} text={'Eurobusiness'} />
            </div>
        </div>
    </div>;
}