import { Navbar } from '../../components/navbar';

import bannerImage from '../../images/ah-group/banner.png';

import tile1 from '../../images/ah-group/tiles/1.png';
import tile2 from '../../images/ah-group/tiles/2.png';
import tile3 from '../../images/ah-group/tiles/3.png';
import tile4 from '../../images/ah-group/tiles/4.png';
import tile5 from '../../images/ah-group/tiles/5.png';
import tile6 from '../../images/ah-group/tiles/6.png';
import tile7 from '../../images/ah-group/tiles/7.png';
import tile8 from '../../images/ah-group/tiles/8.png';
import tile9 from '../../images/ah-group/tiles/9.png';
import tile10 from '../../images/ah-group/tiles/10.png';

import company1 from '../../images/company-logos/h4group.png';
import company2 from '../../images/company-logos/jurga.png';
import company3 from '../../images/company-logos/aparthostel.png';
import company4 from '../../images/company-logos/bocian-everest-finance.png';
import company5 from '../../images/company-logos/everest-development.png';

import { Footer } from '../../components/footer';
import { Banner } from '../../components/banner';
import { SectionTitle } from '../../components/sectionTitle';
import { AHGroupTile } from './components/ahGroupTile';
import { LocalisationsSection } from './components/localisationsSection';
import { useEffect, useState } from 'react';
import AppConfig from '../../utils/appconfig';
import { AHGroupModal } from './components/ahGroupModal';

const currentPage: string = 'ah-group';

export function AHGroup() {
  const [openModalId, setOpenModalId] = useState<number>(-1);

  useEffect(() => {
    document.title = `${AppConfig.baseTabTitle} - O nas`;
  }, []);

  const handleTileClick: any = (tileId: number) => {
    setOpenModalId(tileId);
  };

  return (
    <div className="App theme-light">
      { openModalId === 0 && 
        <AHGroupModal tileId={0} title='Kopalnie' titlePrimary='Nasze aktywności' constName='Ateani Mining Shpk' constDate='2018' constShares='50%'
          image={tile1} logo={company1} logoAlt='H4 Group'
          text={`Obecnie w Albanii w pobliżu Bulqiza posiadamy licencje na wydobycie w trzech kopalniach. Nasze kopalnie specjalizują się w wydobyciu rudy chromu, którego zastosowanie w przemyśle, a także w codziennym życiu jest bardzo szerokie.`}
          onClose={(tileId: number) => setOpenModalId(-1) } />
      }

      { openModalId === 1 && 
        <AHGroupModal tileId={1} title='Chemia budowlana' titlePrimary='Nasze aktywności' constName='Jurga Spółka Jawna' constDate='2017' constShares='77%'
          image={tile2} logo={company2} logoAlt='Jurga' link={'https://jurga.com.pl'} linkText="jurga.com.pl"
          text={`JURGA Spółka jawna powstała w 2000 roku. Pierwsze kroki a zarazem siedziba mieściła się w małej miejscowości Krzyżanowo w województwie Wielkopolskim. Aktualnie centrala oraz produkcja znajdują się w nowo wybudowanym zespole hal magazynowych oraz przylegającym do niego budynku o charakterze biurowym w Zbrudzewie koło Śremu.\n\rSpółka jest producentem chemii budowlanej o ugruntowanej pozycji na rynku rodzimym. W swojej ofercie posiada szeroką gamę wyrobów, które z powodzeniem znajdują zastosowanie na każdym etapie prac remontowych, budowlanych, wykończeniowych a także pomocnych przy pielęgnacji architektury zieleni.`}
          onClose={(tileId: number) => setOpenModalId(-1) } />
      }

      { openModalId === 2 && 
        <AHGroupModal tileId={2} title='Hostele' titlePrimary='Nasze aktywności' constName='H&H Corp - Hawryluk' constDate='2017' constShares='50%'
          image={tile3} logo={company3} logoAlt='Apart Hostel' link={'https://aparthostel.pl'} linkText="aparthostel.pl"
          text={`Aparthostel to aż 600 miejsc noclegowych w jednym budynku, znajdującym się w samym centrum komunikacyjnym dzielnicy Starołęka w Poznaniu. Hostel został stworzony z myślą o długoterminowym pobycie. Wszystkie pokoje posiadają własne łazienki oraz aneksy kuchenne wyposażone w lodówki, kuchenki oraz czajniki. Goście do dyspozycji mają przestronny hol, sejf, pralnię, siłownię zewnętrzna czy wypożyczalnię rowerów. W budynku na dwóch piętrach znajdują się także pomieszczenia biurowe przeznaczone na wynajem, do którego zostało przeznaczone osobne, niezależne wejście na budynku.`}
          onClose={(tileId: number) => setOpenModalId(-1) } />
      }

      { openModalId === 3 && 
        <AHGroupModal tileId={3} title='Działalność finansowa' titlePrimary='Nasze aktywności' constName='Everest Finanse S.A.' constDate='2007' constShares='25%'
          image={tile4} logo={company4} logoAlt='Bocian | Everest Finanse' link={'https://bocianpozyczki.pl'} linkText="bocianpozyczki.pl"
          text={`EVEREST FINANSE S.A. prowadzi działalność w zakresie udzielania pożyczek pieniężnych w domu Klienta już od 2000 roku. Firma oferując Klientom swoje produkty zapewnia dogodną formę dostarczenia środków pieniężnych w gotówce i/lub w formie karty przedpłaconej.\n\r\n\rEVEREST FINANSE S.A. w ciągu ostatnich 10 lat stał się trzeci największym graczem na rynku home credit, uzyskując ponad 11% udziału w rynku. W 2018 roku otrzymał Złotą Statuetkę Lidera Polskiego Biznesu przyznawaną przez BCC, a w 2019 roku spółka ponownie potwierdziła swoją doskonałą kondycję, uzyskując Pierwszy Diament do Złotej Statuetki.`}
          onClose={(tileId: number) => setOpenModalId(-1) } />
      }

      { openModalId === 4 && 
        <AHGroupModal tileId={4} title='Budownictwo mieszkaniowe i usługowe' titlePrimary='Nasze aktywności' constName='Everest Development Hawryluk sp. z o.o.' constDate='2004' constShares='25%'
          image={tile5} logo={company5} logoAlt='Everest Development' link={'https://hhdevelopment.pl'} linkText="hhdevelopment.pl"
          text={`Everest Development Hawryluk Spółka z ograniczoną odpowiedzialnością działa na rynku od 2006 roku. Swoją działalność opiera na budowaniu funkcjonalnych mieszkań oraz lokali usługowo – handlowych, które dedykowane są zarówno do sprzedaży jak i wynajmu. Zasięg działalności spółki to głównie województwo wielkopolskie, gdzie większość inwestycji realizowanych jest w mieście Poznań.`}
          constName2='Development H&H Corp sp. z o.o. sp.k.' constDate2='2020' constShares2='25%'
          text2={`Development H&H Corp Spółka z ograniczona odpowiedzialnością Spółka komandytowa została utworzona w roku 2020. Aktualnie spółka skupia się na budownictwie mieszkaniowym na terenie miasta Śrem, między innymi bloku z 96 lokalami mieszkalnymi na ulicy Korczaka.`}
          onClose={(tileId: number) => setOpenModalId(-1) } />
      }

      { openModalId === 5 && 
        <AHGroupModal tileId={5} title='Budownictwo Hale Magazynowe' titlePrimary='Nasze aktywności' constName='Invest Park Hawryluk Spółka Jawna' constDate='2020' constShares='n/a'
          image={tile6}
          text={`Spółka zajmuję się budownictwem hal magazynowych na terenie województwa wielkopolskiego. Aktualnie ma za sobą inwestycje w postaci wybudowania zespołu trzech hal o charakterze magazynowym i produkcyjnym w Zbrudzewie koło Śremu i jest w trakcie budowania II etapu inwestycji.`}
          onClose={(tileId: number) => setOpenModalId(-1) } />
      }

      { openModalId === 6 && 
        <AHGroupModal tileId={6} title='NOBILIS RECYCLING' titlePrimary='Nasze aktywności' constName='-' constDate='-' constShares='-'
          image={tile7} link={'https://nobilisrecycling.com'} linkText="nobilisrecycling.com"
          text={`Działalność Nobilis Recycling opiera się na wielkotonażowym zakupie zużytych katalizatorów, materiałów nieżelaznych, złomu elektronicznego oraz używanej elektronice i ich powtórnemu przetworzeniu. Zakup surowców odbywa się zarówno od hurtowni, jak i detalistów. Po ich uprzednim przetworzeniu trafiają w efekcie końcowym do największych światowych rafinerii i hut.`}
          onClose={(tileId: number) => setOpenModalId(-1) } />
      }

      { openModalId === 7 && 
        <AHGroupModal tileId={7} title='NOBILIS RECYCLING' titlePrimary='Nasze aktywności' constName='-' constDate='-' constShares='-'
          image={tile8} link={'https://sewelosolaris.pl'} linkText="sewelosolaris.pl"
          text={`Sewelo Solaris świadczy kompleksowe usługi w zakresie fotowoltaiki - począwszy od konsultacji u klienta, przygotowaniem projektu, pomocy w finansowaniu a finalnie w montażu paneli fotowoltaicznych. Usługi świadczone są zarówno dla klientów indywidualnych, rolników, jak i przedsiębiorstw tworzących wielkie farmy fotowoltaiczne.`}
          onClose={(tileId: number) => setOpenModalId(-1) } />
      }

      { openModalId === 8 && 
        <AHGroupModal tileId={8} title='NOBILIS RECYCLING' titlePrimary='Nasze aktywności' constName='-' constDate='-' constShares='-'
          image={tile9}
          text={``}
          onClose={(tileId: number) => setOpenModalId(-1) } />
      } 

      { openModalId === 9 && 
        <AHGroupModal tileId={9} title='Inwestycje zrealizowane' titlePrimary='Nasze aktywności' constName='H4 Group sp. z o.o. sp.k.' constDate='2017' constShares='25%'
          image={tile10} logo={company1} logoAlt='H4 Group'
          text={`Hala magazynowa z funkcją socjalno - biurową powstała w Wałbrzyskiej Strefie Ekonomicznej „INVEST PARK” w podstrefie Bolesławiec w roku 2018. Hala o powierzchni użytkowej 8285 m2 posiada w swym składzie 2 magazyny oraz miejsce na 6 przestronnych biur oraz pomieszczenia socjalne. Był to pierwszy projekt tego typu, który równocześnie zapoczątkował szereg inwestycji w postaci wznoszenia hal, które są aktualnie w realizacji.`}
          onClose={(tileId: number) => setOpenModalId(-1) } />
      } 

      <Navbar currentPage={currentPage} />

      <Banner title={'AH Group'} titlePrimary={'Artur Hawryluk’s Business since 1997'} backgroundImage={bannerImage}
        paragraph1Title={'850'} paragraph1Text={'PRACOWNIKÓW W NASZYCH SPÓŁKACH'} paragraph2Title={'35.000 m2'} paragraph2Text={'POWIERZCHNI NASZYCH BIUR I HAL'} />
      
      <div className="content-wrapper">
        <SectionTitle title={"Nasze aktywności"} titlePrimary={"AH Group"} />

        <div className="content__text-wrapper">
          <p className="content__text">Podstawą działań naszego biznesu jest dywersyfikacja, co daje nam poczucie nie tylko bezpieczeństwa środków przeznaczanych na poszczególne projekty, co także rozwoju w wielu obszarach tematycznych, nieustanne poszerzanie wiedzy i braku monotonii.</p>
          <p className="content__text">Spółki wchodzące do grupy kapitałowej, powiązane są zarówno <span className="content__text--primary">kapitałowo jak i osobowo</span> i są to działalności o charakterze produkcyjnym, usługowym, wydobywczym, transportowym, handlowym a także finansowym. </p>
        </div>

        <div className="ah-group__tiles-wrapper">
          <AHGroupTile tileId={0} image={tile1} title="Kopalnie" imageAlt="Ciężki sprzęt w kopalni" onClick={(tileId: number) => handleTileClick(tileId)} />
          <AHGroupTile tileId={1} image={tile2} title="Chemia budowlana" imageAlt="Grupa ludzi w kaskach ochronnych w fabryce" onClick={(tileId: number) => handleTileClick(tileId)} />
          <AHGroupTile tileId={2} image={tile3} title="Hostele" imageAlt="Nowoczesny budynek - hostel" onClick={(tileId: number) => handleTileClick(tileId)} />
          <AHGroupTile tileId={3} image={tile4} title="Działalność finansowa" imageAlt="Bocian maskotka" onClick={(tileId: number) => handleTileClick(tileId)} />
        </div>

        <div className="ah-group__tiles-wrapper">
          <AHGroupTile tileId={4} image={tile5} title="Budownictwo mieszkaniowe i usługowe" imageAlt="Budowa" onClick={(tileId: number) => handleTileClick(tileId)} />
          <AHGroupTile tileId={5} image={tile6} title="Budownictwo Hale Magazynowe" imageAlt="Hala magazynowa" onClick={(tileId: number) => handleTileClick(tileId)} />
          <AHGroupTile tileId={6} image={tile7} title="NOBILIS RECYCLING" imageAlt="Linia w zakładzie recyklingu" onClick={(tileId: number) => handleTileClick(tileId)} />
          <AHGroupTile tileId={7} image={tile8} title="SEWELO SOLARIS" imageAlt="Panele słoneczne" onClick={(tileId: number) => handleTileClick(tileId)} />
        </div>

        <div className="ah-group__tiles-wrapper">
          <AHGroupTile tileId={8} image={tile9} title="Apartamenty" imageAlt="Luksusowy apartament" onClick={(tileId: number) => handleTileClick(tileId)} />
          <AHGroupTile tileId={9} image={tile10} title="Inwestycje zrealizowane" imageAlt="Hale i fabryki z lotu ptaka" onClick={(tileId: number) => handleTileClick(tileId)} />
          <AHGroupTile tileId={11} image={tile1} title="" imageAlt="" onClick={(tileId: number) => { return }} invisible={true} />
          <AHGroupTile tileId={12} image={tile1} title="" imageAlt="" onClick={(tileId: number) => { return }} invisible={true} />
        </div>
      </div>

      <LocalisationsSection />

      <Footer />
    </div>
  );
}
