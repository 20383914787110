import iconArrow from '../../../images/icon-arrow-right.png';

export interface IAHGroupTileProps {
    image: any;
    imageAlt: string;
    title: string;
    tileId: number;
    invisible?: boolean;
    onClick: (tileId: number) => void;
}

export const AHGroupTile = (props: IAHGroupTileProps) => {
    return <div className={`ah-group-tile ${props.invisible ? 'ah-group-tile--invisible' : ''}`} onClick={ () => props.onClick(props.tileId) }>
        <div className="ah-group-tile__image-wrapper">
            <img src={props.image} className="ah-group-tile__image" alt={props.imageAlt} loading="lazy" />
        </div>
        
        <div className="ah-group-tile__title-wrapper">
            <p className="ah-group-tile__title">{props.title}</p>
            
            <img src={iconArrow} className="ah-group-tile__arrow-icon" alt="Ikona strzałki w prawo" />
        </div>
    </div>;
}