import { LinkButton } from '../../../components/linkButton';

export interface IBiuraCompanyGroupProps {
    image: any;
    imageAlt: string;
    title: string;
    text: string;
    text2?: string;
    buttonText?: string;
    buttonLinkTo?: string;
}

export const BiuraCompanyGroup = (props: IBiuraCompanyGroupProps) => {
    return <div className="biura__group-wrapper">
        <img className="biura__company-logo" src={props.image} alt={props.imageAlt} />
        
        <div className="biura__group-text-wrapper">
            <h4 className="biura__title">{props.title}</h4>
            <p className="biura__text">{props.text}</p>
            
            {!!props.text2 && 
                <p className="biura__text--additional">{props.text2}</p>
            }

            {(!!props.buttonText && !!props.buttonLinkTo) &&
                <LinkButton buttonText={props.buttonText} linkTo={props.buttonLinkTo} customClassName="biura__button" />
            }
        </div>
    </div>;
}