import decoration from '../images/decoration-lines.png';

export interface IBannerProps {
    backgroundImage: any;
    title: string;
    titlePrimary: string;
    paragraph1Title?: string;
    paragraph1Text?: string;
    paragraph2Title?: string;
    paragraph2Text?: string;
    customClass?: string;
    // onShowChange: (value: boolean) => void;
}

export const Banner = (props: IBannerProps) => {
    return <div className={`banner ${props.customClass}`}>
        <div className="content-wrapper banner__background" style={{backgroundImage: `url(${props.backgroundImage})`}}>
            <img src={decoration} className="banner__decoration" alt="Linie dekoracyjne" loading="lazy" />
            
            <div className="banner__content-wrapper">
                <div className="banner__header-wrapper">
                    <p className="banner__header-title--primary">{props.titlePrimary}</p>
                    <h3 className="banner__header-title">{props.title}</h3>
                </div>

                <div className="banner__paragraphs-wrapper">
                    {(!!props.paragraph1Text || !!props.paragraph1Title) && 
                        <div className="banner__paragraph-wrapper">
                            <h4 className="banner__paragraph-title">{props.paragraph1Title}</h4>
                            <p className="banner__paragraph-text">{props.paragraph1Text}</p>
                        </div>
                    }

                    {(!!props.paragraph2Text || !!props.paragraph2Title) && 
                        <div className="banner__paragraph-wrapper">
                            <h4 className="banner__paragraph-title">{props.paragraph2Title}</h4>
                            <p className="banner__paragraph-text">{props.paragraph2Text}</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>;
}