import { Navbar } from '../../components/navbar';

import bannerImage from '../../images/biura/banner.png';

import ahGroupLogo from '../../images/company-logos/ah-group.png';
import everestFinanceLogo from '../../images/company-logos/everest-finance.png';
import jurgaLogo from '../../images/company-logos/jurga.png';
import aparthostelLogo from '../../images/company-logos/aparthostel.png';
import h4groupLogo from '../../images/company-logos/h4group.png';
import everestDevelopmentLogo from '../../images/company-logos/everest-development.png';

import { Footer } from '../../components/footer';
import { Banner } from '../../components/banner';
import { useEffect } from 'react';
import AppConfig from '../../utils/appconfig';
import { BiuraCompanyGroup } from './components/biuraCompanyGroup';

const currentPage: string = 'biura-spolek';

export function Biura() {
  useEffect(() => {
    document.title = `${AppConfig.baseTabTitle} - Biura spółek`;
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <Banner title={'Biura spółek'} titlePrimary={'AH Group'} backgroundImage={bannerImage} />
      
      <div className="content-wrapper">
        <BiuraCompanyGroup image={ahGroupLogo} imageAlt={"AH Group - logo"} title="Centrala spółki" 
          buttonLinkTo='/kontakt' buttonText='Skontaktuj się z nami'
          text="Centrala naszej spółki mieści się w Śremie przy ulicy Chopina 1 J na II piętrze"
          text2="Centrala czynna jest w godzinach 8:30 – 16:00 od poniedziałku do piątku."
        />

        <BiuraCompanyGroup image={everestFinanceLogo} imageAlt={"Everest Finance - logo"} title="Everest Finanse S.A." 
          text="ul. Stary Rynek 88, 61-772 Poznań" text2="ul. Sikorskiego 6, 63-100 Śrem"
        />

        <BiuraCompanyGroup image={jurgaLogo} imageAlt={"Jurga - logo"} title="Jurga Spółka Jawna" 
          text="Krzyżanowo 33, 63-100 Śrem"
        />

        <BiuraCompanyGroup image={aparthostelLogo} imageAlt={"Apart Hostel - logo"} title="Apart Hostel" 
          text="Ul. Forteczna 17 B, 61-772 Poznań"
        />

        <BiuraCompanyGroup image={h4groupLogo} imageAlt={"H4 Group - logo"} title="H4 Group Chrome" 
          text="Albania, Tirana"
        />

        <BiuraCompanyGroup image={everestDevelopmentLogo} imageAlt={"Everest Development - logo"} title={`Development H&H Corp Sp. z o.o. Sp.k\n\rInvest Park Hawryluk Spółka Jawna`} 
          text="Grunwaldzka 27 C, Śrem"
        />
      </div>

      <Footer />
    </div>
  );
}
