import { Navbar } from '../../components/navbar';

import bannerImage from '../../images/kontakt/banner.png';
import bannerImageMobile from '../../images/kontakt/bannerMobile.png';

import { Footer } from '../../components/footer';
import { useEffect } from 'react';
import AppConfig from '../../utils/appconfig';
import { SectionTitle } from '../../components/sectionTitle';

const currentPage: string = 'kontakt';

export function Kontakt() {
  useEffect(() => {
    document.title = `${AppConfig.baseTabTitle} - Kontakt`;
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <img src={bannerImageMobile} className="kontakt__banner--mobile" alt="Widok dwóch biurowców oraz nieba" loading="lazy" />

      {/* <div className="content-wrapper"> */}
        <div className='kontakt__sections-wrapper'>
          <img src={bannerImage} className="kontakt__banner" alt="Widok dwóch biurowców oraz nieba" loading="lazy" />

          <div className="kontakt__content-wrapper content-wrapper">
            <SectionTitle title={"Kontakt"} titlePrimary={"AH Group"} customClassName="kontakt__section-title" />

            <p className='kontakt__contact-info-text'>Zapraszamy do bezpośredniego kontaktu</p>

            <div className='kontakt__contact-info-group'>
              <p className='kontakt__contact-info-subtitle'>Asystentka biura zarządu</p>
              <h4 className='kontakt__contact-info-title'>Agata Domałeczna</h4>
              <a className='kontakt__contact-info-email' href="mailto:agata.domaleczna@ahgroup.com.pl">agata.domaleczna@ahgroup.com.pl</a>
              <a className='kontakt__contact-info-phone' href="tel:+48876543210">+48 876 543 210</a>
            </div>

            <div className='kontakt__contact-info-group'>
              <p className='kontakt__contact-info-subtitle'>Poznań</p>
              <h4 className='kontakt__contact-info-title'>Apart Hostel</h4>
              <a className='kontakt__contact-info-email' href="mailto:kontakt@aparthostel.pl">kontakt@aparthostel.pl</a>
            </div>

            <div className='kontakt__contact-info-group'>
              <p className='kontakt__contact-info-subtitle'>Śrem</p>
              <h4 className='kontakt__contact-info-title'>Everest Finanse S.A.</h4>
              <a className='kontakt__contact-info-email' href="mailto:biuro@bocianpozyczki.pl">biuro@bocianpozyczki.pl</a>
            </div>

            <div className='kontakt__contact-info-group'>
              <h4 className='kontakt__contact-info-title'>Albania</h4>
              <a className='kontakt__contact-info-email' href="mailto:kontakt@albaniadomain.alb">kontakt@albaniadomain.alb</a>
            </div>

            <p className='kontakt__contact-info-text'>
              Jeżeli chcą Państwo pozostawić wiadomość dla Prezesa Grupy zapraszamy do skorzystania z poniższego adresu mailowego 
              <a className='kontakt__contact-info-text--mail' href="mailto:hawryluk@ahgroup.com.pl"> hawryluk@ahgroup.com.pl</a>
            </p>
          </div>
        </div>
      {/* </div> */}
      
      <Footer />
    </div>
  );
}
