import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './scss/main.scss';

import { HomePage } from './pages/Home';
import { Historia } from './pages/Historia';
import { Wartosci } from './pages/Wartosci';
import { ArturHawryluk } from './pages/ArturHawryluk';
import { AHGroup } from './pages/AHGroup';
import { NotFound } from './pages/404';
import { Aktualnosci } from './pages/Aktualnosci';
import { Kontakt } from './pages/Kontakt';
import { Biura } from './pages/Biura';
import { PolitykaPrywatnosci } from './pages/PolitykaPrywatnosci';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/ah-group" exact component={AHGroup} />
      <Route path="/historia" exact component={Historia} />
      <Route path="/wartosci" exact component={Wartosci} />
      <Route path="/artur-hawryluk" exact component={ArturHawryluk} />
      <Route path="/biura-spolek" exact component={Biura} />
      <Route path="/aktualnosci" exact component={Aktualnosci} />
      <Route path="/kontakt" exact component={Kontakt} />
      <Route path="/polityka-prywatnosci" exact component={PolitykaPrywatnosci} />
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;