import { LinkButton } from '../../../components/linkButton';
import { SectionTitle } from '../../../components/sectionTitle';
import iconClose from '../../../images/icon-close-gold.png';

export interface IAHGroupModalProps {
    hide?: boolean;
    image: any;
    logo?: any;
    logoAlt?: string;
    title: string;
    titlePrimary: string;
    constName: string;
    constDate: string;
    constShares: string;
    text: string;
    tileId: number;
    constName2?: string;
    constDate2?: string;
    constShares2?: string;
    text2?: string;
    link?: string;
    linkText?: string;
    onClose: (tileId: number) => void;
}

export const AHGroupModal = (props: IAHGroupModalProps) => {
    return <div className={`ah-group-modal__blend ${props.hide ? 'ah-group-modal__blend--hide' : ''}`}>
        <div className={`ah-group-modal ${props.hide ? 'ah-group-modal--hide' : ''}`}>
            <div className={`ah-group-modal__banner-warpper`} style={{backgroundImage: `url(${props.image})`}}>
                <img onClick={ () => props.onClose(props.tileId) } className="ah-group-modal__close-icon" src={iconClose} alt="Zamknij"
                    title="Zamknij" />

                { props.logo &&
                    <img className="ah-group-modal__banner-logo" src={props.logo} alt={props.logoAlt} title={props.logoAlt} />
                }
            </div>

            <div className={`ah-group-modal__content-warpper content-wrapper`}>
                <SectionTitle title={props.title} titlePrimary={props.titlePrimary} />

                <div className={`ah-group-modal__constants-wrapper`}>
                    <div className={`ah-group-modal__constants-row`}>
                        <p className={`ah-group-modal__constants-title`}>Nazwa spółki</p>
                        <h6 className={`ah-group-modal__constants-value`}>{props.constName}</h6>
                    </div>

                    <div className={`ah-group-modal__constants-row`}>
                        <p className={`ah-group-modal__constants-title`}>Data inwestycji</p>
                        <h6 className={`ah-group-modal__constants-value`}>{props.constDate}</h6>
                    </div>

                    <div className={`ah-group-modal__constants-row`}>
                        <p className={`ah-group-modal__constants-title`}>Udziały</p>
                        <h6 className={`ah-group-modal__constants-value`}>{props.constShares}</h6>
                    </div>
                </div>

                <p className={`ah-group-modal__text content__text`}>{props.text}</p>

                { (!!props.constName2 && !!props.constDate2 && !!props.constShares2) &&
                    <div className={`ah-group-modal__constants-wrapper`}>
                        <div className={`ah-group-modal__constants-row`}>
                            <p className={`ah-group-modal__constants-title`}>Nazwa spółki</p>
                            <h6 className={`ah-group-modal__constants-value`}>{props.constName2}</h6>
                        </div>

                        <div className={`ah-group-modal__constants-row`}>
                            <p className={`ah-group-modal__constants-title`}>Data inwestycji</p>
                            <h6 className={`ah-group-modal__constants-value`}>{props.constDate2}</h6>
                        </div>

                        <div className={`ah-group-modal__constants-row`}>
                            <p className={`ah-group-modal__constants-title`}>Udziały</p>
                            <h6 className={`ah-group-modal__constants-value`}>{props.constShares2}</h6>
                        </div>
                    </div>
                }

                { !!props.text2 &&
                    <p className={`ah-group-modal__text content__text`}>{props.text2}</p>
                }

                { !!props.link &&
                    <LinkButton buttonText={!!props.linkText ? props.linkText : props.link} linkTo={props.link} target={"_blank"}
                        customStyle={{width: '100%'}}
                        customButtonStyle={{width: '100%', padding: '20px 18px'}} />
                }
            </div>
        </div>
    </div>;
}