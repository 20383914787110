import { Link } from "react-router-dom";
import { LinkButton } from "../../../components/linkButton";
import decoration from '../../../images/decoration-lines.png';

export interface IHomeTileProps {
    title: string;
    pictureMobile: any;
    picture: any;
    linkTo: string;
    caption: string;
    buttonText: string;
    pictureAlt: string;
    // onShowChange: (value: boolean) => void;
}

export const HomeTile = (props: IHomeTileProps) => {
    const { title, picture, pictureMobile, linkTo, caption, buttonText, pictureAlt } = props;

    return <Link to={linkTo} className="home-tile__wrapper" onClick={(e) => {
        let domElement: any = document.querySelector('.home-tile__button-link-wrapper');
        if(window.getComputedStyle(domElement).display === 'flex') {
            e.preventDefault();
        }
    }}>
        <img src={decoration} className="home-tile__decoration" alt="Linie dekoracyjne" loading="lazy" />

        <img src={pictureMobile} className="home-tile__picture" alt={pictureAlt} loading="lazy" />
        <img src={picture} className="home-tile__picture--desktop" alt={pictureAlt} loading="lazy" />

        <div className="home-tile__text-wrapper">
            <h5 className="home-tile__title">{title}</h5>
            <p className="home-tile__caption">{caption}</p>
            
            <LinkButton buttonText={buttonText} linkTo={linkTo} customClassName="home-tile__button-link-wrapper" />
        </div>
    </Link>;
}