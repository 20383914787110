import { Navbar } from '../../components/navbar';

import bannerImage from '../../images/aktualnosci/banner.png';

import { Footer } from '../../components/footer';
import { Banner } from '../../components/banner';
import { useEffect } from 'react';
import AppConfig from '../../utils/appconfig';

const currentPage: string = 'aktualnosci';

export function Aktualnosci() {
  useEffect(() => {
    document.title = `${AppConfig.baseTabTitle} - Aktualności`;
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <Banner title={'Aktualności'} titlePrimary={'AH Group'} backgroundImage={bannerImage} />
      
      <div className="content-wrapper">
        <p className='aktualnosci__text'>Brak aktualnych komunikatów</p>
      </div>

      <Footer />
    </div>
  );
}
