import { Navbar } from '../../components/navbar';

import bannerImage from '../../images/aktualnosci/banner.png';

import { Footer } from '../../components/footer';
import { Banner } from '../../components/banner';
import { useEffect } from 'react';
import AppConfig from '../../utils/appconfig';

const currentPage: string = 'polityka-prywatnosci';

export function PolitykaPrywatnosci() {
  useEffect(() => {
    document.title = `${AppConfig.baseTabTitle} - Polityka Prywatności`;
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <Banner title={'Polityka Prywatności'} titlePrimary={'AH Group'} backgroundImage={bannerImage} />

      <div className="artur-hawryluk__section-wrapper artur-hawryluk__section-wrapper--reverse">
        <p className="artur-hawryluk__text artur-hawryluk__text--no-padding-vertical">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus quis tortor eget lacus ultrices luctus. Curabitur at pellentesque orci. Quisque sed risus ut lorem viverra volutpat. Duis venenatis convallis maximus. Cras gravida tellus libero, vel convallis orci auctor et. Vestibulum aliquam eu ex luctus cursus. In accumsan suscipit nunc. Suspendisse nec semper lorem, sit amet cursus eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Ut laoreet lorem in dolor malesuada sodales. Phasellus pretium at erat vel vestibulum. Maecenas quam dolor, fermentum vitae tellus at, fringilla ullamcorper odio. Vivamus non suscipit lectus. Mauris ut arcu orci. Donec justo tortor, porta et quam at, faucibus maximus felis. Praesent fermentum facilisis auctor.
          <br /><br />
          Vivamus eget orci quam. Maecenas vestibulum, felis a ullamcorper tempor, lorem orci efficitur est, sed fermentum odio dolor in tellus. In a ornare metus. Duis at ante ut tellus venenatis vestibulum. Sed hendrerit dolor pretium orci lacinia scelerisque nec id tellus. Donec pretium mi quis tellus aliquet pharetra. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec et mi a est interdum cursus. Fusce tempor cursus magna eget euismod. Sed est tortor, aliquet eget dolor eu, commodo pulvinar enim. Maecenas lectus massa, convallis id dapibus vitae, porttitor et ante. Vestibulum lacinia a nunc a bibendum. Aenean tempus turpis massa, id malesuada erat hendrerit sed. Morbi accumsan orci leo, quis gravida tellus posuere nec.
          <br /><br />
          Pellentesque eget laoreet nulla. Sed placerat eros leo, ac pellentesque eros vulputate at. Sed ultricies, quam quis gravida mattis, erat enim suscipit nisl, ac euismod massa nisi sed lacus. Aenean egestas vulputate suscipit. Etiam dictum diam vitae metus scelerisque lacinia. Nulla dui magna, accumsan sit amet fermentum sit amet, semper quis arcu. Fusce fringilla pharetra condimentum. Aliquam ac orci at sem tincidunt consequat. Phasellus vel justo convallis, imperdiet risus quis, porttitor lectus. Nullam eleifend non erat in venenatis. Donec id augue eu purus pretium lacinia.
          <br /><br />
          Praesent eget diam varius, dapibus risus eget, pharetra lectus. In posuere, leo sit amet dignissim ullamcorper, turpis arcu porta erat, id pretium justo tortor at mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in vulputate sapien, in semper sem. Nullam hendrerit arcu sit amet mi cursus tristique. Maecenas pellentesque justo id vestibulum faucibus. Cras auctor pulvinar libero a fermentum. Pellentesque risus enim, congue sit amet justo et, pharetra commodo neque. Maecenas ac imperdiet nisl. In hac habitasse platea dictumst. Aliquam erat volutpat. Donec finibus lectus odio, ac vehicula est convallis sed. Quisque pretium massa nec ante suscipit, et ultricies sem pretium. Curabitur maximus pulvinar laoreet. Etiam molestie nisl ut enim sagittis gravida.
          <br /><br />
          Nullam diam turpis, rhoncus sit amet ligula ut, cursus convallis arcu. Nunc ultrices elit ut fringilla faucibus. Suspendisse euismod nunc at nisi hendrerit tincidunt. Integer eros est, tristique a molestie ut, ornare ac mi. Praesent ut mauris lobortis, dapibus arcu quis, semper nisi. Nam fringilla purus ut pulvinar consectetur. Maecenas elementum consectetur justo non commodo. Etiam lorem nulla, egestas sed purus rutrum, interdum eleifend dolor. Pellentesque at aliquet elit. Nunc vitae dui vel elit porttitor tristique. Nunc vitae accumsan magna.
        </p>
      </div>

      <Footer />
    </div>
  );
}
