import { Navbar } from '../../components/navbar';
import { HomeTile } from './components/homeTile';

import pic1Mobile from '../../images/home/1-mobile.png';
import pic1 from '../../images/home/1.png';

import pic2Mobile from '../../images/home/2-mobile.png';
import pic2 from '../../images/home/2.png';

import pic3Mobile from '../../images/home/3-mobile.png';
import pic3 from '../../images/home/3.png';

import pic4Mobile from '../../images/home/4-mobile.png';
import pic4 from '../../images/home/4.png';

import { Footer } from '../../components/footer';
import AppConfig from '../../utils/appconfig';
import { useEffect } from 'react';

const currentPage: string = 'home';

export function HomePage() {
  useEffect(() => {
    document.title = `${AppConfig.baseTabTitle} - Strona główna`;
  }, []);
  
  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <div className="home__tiles-wrapper">
        <HomeTile title="AH Group" picture={pic1} pictureMobile={pic1Mobile} caption="Artur Hawryluk’s Business since 1997" 
          buttonText="Dowiedz się więcej" linkTo="/ah-group" pictureAlt="Ciężki sprzęt w kopalni" />
        
        <HomeTile title="Nasza historia" picture={pic2} pictureMobile={pic2Mobile} caption="Na rynku od 1997" 
          buttonText="Dowiedz się więcej" linkTo="/historia" pictureAlt="Miejski krajobraz" />

        <HomeTile title="Artur Hawryluk" picture={pic3} pictureMobile={pic3Mobile} caption="Człowiek wielu pasji" 
          buttonText="Dowiedz się więcej" linkTo="/artur-hawryluk" pictureAlt="Pan Artur Hawryluk" />

        <HomeTile title="Biznes z wartościami" picture={pic4} pictureMobile={pic4Mobile} caption="Hasła przekuwamy na działanie" 
          buttonText="Dowiedz się więcej" linkTo="/wartosci" pictureAlt="Zawodnik na wózku inwalidzkim podczas wyścigu" />
      </div>

      <Footer />
    </div>
  );
}
