import decoration from '../images/decoration-lines.png';

export interface ISectionTitleProps {
    title: string;
    titlePrimary: string;
    customClassName?: string;
    // onShowChange: (value: boolean) => void;
}

export const SectionTitle = (props: ISectionTitleProps) => {
    return <div className={`section-title__wrapper ${props.customClassName}`}>
        <img src={decoration} className="section-title__decoration" alt="Linie dekoracyjne" loading="lazy" />
        
        <p className={`section-title__title--primary`}>{props.titlePrimary}</p>
        <h4 className={`section-title__title`}>{props.title}</h4>
    </div>;
}