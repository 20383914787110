import { Link } from "react-router-dom";
import arrowIcon from '../images/icon-arrow-right.png';

export interface ILinkButtonProps {
    linkTo: string;
    buttonText: string;
    customStyle?: any;
    customButtonStyle?: any;
    customClassName?: string;
    target?: string;
    // onShowChange: (value: boolean) => void;
}

export const LinkButton = (props: ILinkButtonProps) => {
    const { linkTo, buttonText } = props;

    const buttonContent = <button className="custom-button" style={props.customButtonStyle}>
        {buttonText}
        <img src={arrowIcon} className="custom-button__icon" alt={'Ikona strzałki w prawo'} />
    </button>;

    return (!!props.target && props.target === '_blank') ?
        <a href={linkTo} target={props.target} className={`custom-button__link-wrapper ${props.customClassName}`} style={props.customStyle}>
            {buttonContent}
        </a>
        :
        <Link to={linkTo} target={props.target} className={`custom-button__link-wrapper ${props.customClassName}`} style={props.customStyle}>
            {buttonContent}
        </Link>;
}