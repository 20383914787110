import dotImage from '../../../images/dot.png';
import dotBigImage from '../../../images/dot-big.png';

export interface IHistoriaRowTimelineRowProps {
    year: any;
    bigRow: boolean;
    text?: string;
}

export const HistoriaRowTimelineRow = (props: IHistoriaRowTimelineRowProps) => {
    return <div className={`timeline__row-wrapper ${props.bigRow && 'timeline__row-wrapper--prime'}`}>
        <h5 className={`timeline__year ${props.bigRow && 'timeline__year--prime'}`}>{props.year}</h5>
        
        <img className="timeline__image" src={props.bigRow === true ? dotBigImage : dotImage} alt={'Romb dekoracyjny'} />

        <p className={`timeline__text`} dangerouslySetInnerHTML={{__html: props.text ? props.text : ''}}>
            {/* {props.text ? props.text : ''} */}
        </p>
    </div>;
}