import { Navbar } from '../../components/navbar';

import bannerImage from '../../images/wartosci/banner.png';
import sectionImage from '../../images/wartosci/section.png';

import { Footer } from '../../components/footer';
import { Banner } from '../../components/banner';
import { useEffect } from 'react';
import AppConfig from '../../utils/appconfig';

const currentPage: string = 'wartosci';

export function Wartosci() {
  useEffect(() => {
    document.title = `${AppConfig.baseTabTitle} - Wartości`;
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <Banner title={'Biznes z wartościami'} titlePrimary={'Hasła przekuwamy na działanie'} backgroundImage={bannerImage} customClass="wartosci__banner"
        paragraph1Text="Modne w biznesie hasło wartości, przekuwamy na konkretne działania. Naszą najgłębszą troską są dwa obszary, w których chcemy postępować i promować najszlachetniejsze wartości, a dotyczą one:" />
      
      <div className="wartosci__section-wrapper">
        <img src={sectionImage} className="wartosci__image" alt="Zawodnik na wózku inwalidzkim podczas wyścigu" loading="lazy" />
        
        <div className="wartosci__section-text-wrapper">
          <div className="wartosci__section-text-row">
            <span className="wartosci__section-text-number">1</span>
            <p className="wartosci__section-text">
              Naszych partnerów biznesowych, odbiorców, dostawców usług oraz pracowników
            </p>
          </div>

          <div className="wartosci__section-text-row">
            <span className="wartosci__section-text-number">2</span>
            <p className="wartosci__section-text">
              Ludzi posiadających pasję, tych którym życie przysporzyło trudności lub też dotknęło niesprawiedliwością
            </p>
          </div>
        </div>
      </div>

      <div className="content-wrapper">
        <p className="wartosci__text">
          W ostatnich latach udzieliliśmy wsparcia takim podmiotom jak <span className="wartosci__text--bold">Śremski Klub Sportowy Warta Śrem, Klub Sportowy Taekwondo Rapid Śrem, Polski Związek Wędkarski Koło nr 129 –  Odlewnik Śrem</span> czy fundacja <span className="wartosci__text--bold">„Dzielmy się uśmiechem Krystiania Popieli”</span>
        </p>
      </div>

      <Footer />
    </div>
  );
}
