import { Footer } from "../../components/footer";
import { Navbar } from "../../components/navbar";

export function NotFound() {
  return (
    <div className="App theme-light">
      <Navbar currentPage={'home'} />

      <div className="content-wrapper">
        <h3 style={{marginTop: '100px', fontSize: '1.5rem'}}>404 - nie znaleziono strony!</h3>
      </div>

      <Footer />
    </div>
  );
}
