import { Navbar } from '../../components/navbar';

import bannerImage from '../../images/historia/banner.png';

import { Footer } from '../../components/footer';
import { Banner } from '../../components/banner';
import { useEffect } from 'react';
import AppConfig from '../../utils/appconfig';
import { HistoriaRowTimelineRow } from './components/historiaRowTimelineRow';

const currentPage: string = 'historia';

export function Historia() {
  useEffect(() => {
    document.title = `${AppConfig.baseTabTitle} - Nasza historia`;
  }, []);

  return (
    <div className="App theme-light">
      <Navbar currentPage={currentPage} />

      <Banner title={'Nasza historia'} titlePrimary={'AH Group'} backgroundImage={bannerImage} />
      
      <div className="content-wrapper">
        <div className="historia__content-wrapper">
          <HistoriaRowTimelineRow year={'1997'} bigRow={true} text={'Początek działalności Euro-Art'} />
          <HistoriaRowTimelineRow year={'1998'} bigRow={false} />
          <HistoriaRowTimelineRow year={'1999'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2000'} bigRow={true} text={'Początek działalności dzisiejszej spółki Everest Finanse S.A. pod nazwą Kredyt Express'} />
          <HistoriaRowTimelineRow year={'2001'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2002'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2003'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2004'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2005'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2006'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2007'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2008'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2009'} bigRow={true} text={'Początek działalności dzisiejszej spółki Everest Finanse S.A. pod nazwą Kredyt Express'} />
          <HistoriaRowTimelineRow year={'2010'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2011'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2012'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2013'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2014'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2015'} bigRow={false} />
          <HistoriaRowTimelineRow year={'2016'} bigRow={true} text={'Inwestycja w spółkę Jurga Chemia Budowlana'} />
          <HistoriaRowTimelineRow year={'2017'} bigRow={true} text={'Rozpoczęcie budowy Apart Hostelu'} />
          <HistoriaRowTimelineRow year={'2018'} bigRow={true} text={'Rozpoczęcie inwestycji w Albanii'} />
          <HistoriaRowTimelineRow year={'2019'} bigRow={true} text={'Oddanie Apart Hostelu\n\r<span>Maj</span> - powstaje spółka centralna dla wszystkich spółek\n\r<span>Grudzień</span> - powstanie spółek Development H&H Corp Sp. z o.o. Sp. k. i Invest Park H&H Corp Sp. z o.o. Sp. k.'} />
        </div>
      </div>

      <Footer />
    </div>
  );
}
